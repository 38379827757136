<template>
  <div class="app" :style="{ height: '100%' }">
      <div
        v-if="showSuccess"
        class="d-flex flex-column justify-center align-center"
        style="height: 100%; width: 100%"
      >
        <lottie
            :options="successAnimationOptions"
            :height="100"
            :width="100"
            v-on:animCreated="handleSuccessAnimation"
          />
          <div>
            <div class="text-h4">
              {{ $t('forms.view.successTitle') }}
            </div>
            <div class="mt-2">
              {{$t('forms.view.successMessage')}}
            </div>
          </div>
      </div>
      <div v-else-if="!loading && errorMessage" class="error-display d-flex justify-center align-center text-h4">{{ errorMessage }}
      </div>
      <div v-else-if="block && !loading">

   
      <FlowFormBlockPage  :block="block" @unaccessibleView="unaccessibleView" />
      
      <v-container >
      <v-row justify="center">

      <v-col
          cols="12"
          sm="10"
          md="9"
          lg="8"
          
        >
        <v-row justify="end">

            <v-btn
              v-if="canSubmit && !loading && !errorMessage"
              :disabled="!validated"
              :loading="submitting"
              class="mr-6 mb-8"
              @click="submitBlock"
              color="primary"
            >{{$t('blocks.submitPage')}}</v-btn>
        </v-row >
      </v-col>


            </v-row >
    </v-container>
  </div>
    <LoadingOverlay :show="loading" />
    <LoginDialog
      @credentialsProvided="loadView"
      v-model="loginDialog"
      :resource="flowInstanceLinkUri"
    />
  </div>

</template>

<script>

import LoadingOverlay from '@/components/LoadingOverlay.vue'
import FlowFormBlockPage from '@/components/block/FlowFormBlockPage.vue'
import Lottie from 'vue-lottie'
import * as successAnimationData from '@/assets/success.json'
import ApiClient from '@/ApiClient'
import linkAuthorizationNeededBus from '@/utils/linkAuthorizationNeededBus'
import LoginDialog from '@/components/LoginDialog.vue'

export default {
  props: ['uri'],
  data() {
    return {
      loading: false,
      submitting: false,
      errorMessage: undefined,
      flowInstanceLinkUri: null,
      showSuccess: false,
      block: null,
      loginDialog: false,
      successAnimationOptions: {
        animationData: successAnimationData.default,
        loop: false,
        color: 'red'
      },
    }
  },
  mounted() {
    if(!this.uri) {
      // here we get the uri from the search params as vue router ignores queries that are before the /#view/
      const urlParams = new URLSearchParams(window.location.search)
      this.flowInstanceLinkUri = urlParams.get('uri') 
    }
    else if(this.uri)  {
      this.flowInstanceLinkUri = this.uri
    }
    linkAuthorizationNeededBus.onLinkAuthorizationNeeded(this.onLinkAuthorizationNeeded)
    this.loadView()
  },
  beforeDestroy() {
    linkAuthorizationNeededBus.linkAuthorizationNeededOff(this.onLinkAuthorizationNeeded)
  },
  methods: {
    onLinkAuthorizationNeeded() {
      // show login
      this.loginDialog = true
    },
    credentialsProvided() {
      this.canLogout = true
      this.loadView()
    },
    async loadView() {
        this.loading = true
        this.errorMessage = null
        try {
          const newBlock = await this.$store.dispatch('AGReadBlock', { blockUri: this.flowInstanceLinkUri,  customErrorHandling: [404, 400] })
          this.block = newBlock

        } catch (error) { 
          if (error.response?.status === 404) {
            this.errorMessage = this.$t('errorMessages.unaccessibleView')
          }
          else if (error.response?.status === 400) {
            this.errorMessage = error.response.data.description
            this.showSuccess = true
          }
        }
        finally {
          this.loading = false
        }
    },
    unaccessibleView() {
      this.showSuccess = true
    },
    async submitBlock() {
      // colect values 
      const payload = this.block.payload()

      this.submitting = true
      const response = await ApiClient.postUri( this.submitAction.uri, payload )
      const aLink = response.headers.location
      this.submitting = false
      this.flowInstanceLinkUri = aLink
      this.loadView()
    },
    logout() {
      //reload view to rest auth setting in api client
      window.location.reload()
    },
    handleSuccessAnimation(animation) {
      animation.play()
    },
  },
  computed: {
    canSubmit() {
      return this.submitAction
    },
    validated() {
      return this.block?.children?.every(child => child.validate())
    },
    submitAction() {
      return this.block?.actions?.find( action => action.name == 'submit')
    },
  },
  components: {
    LoadingOverlay,
    FlowFormBlockPage,
    lottie: Lottie,
    LoginDialog
  }
}
</script>

<style scoped>
.app {
  background-color: white;
}


.error-display {
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>