import CellInputBlockVue from '@/components/block/CellInputBlock.vue'
import EmbedBlockVue from '@/components/block/EmbedBlock.vue'
import HeaderBlockVue from '@/components/block/HeaderBlock.vue'
import ParagraphBlockVue from '@/components/block/ParagraphBlock.vue'
import ResourceBlockVue from '@/components/block/ResourceBlock.vue'
import VideoBlockVue from '@/components/block/VideoBlock.vue'

import i18n from '@/plugins/i18n'
import EmbedBlock from '@/store/models/blocks/EmbedBlock'
import FormInputBlock from '@/store/models/blocks/FormInputBlock'
import HeaderBlock, { HEADER_LEVELS } from '@/store/models/blocks/HeaderBlock'
import PageBlock from '@/store/models/blocks/PageBlock'
import ParagraphBlock from '@/store/models/blocks/ParagraphBlock'
import ResourceBlock from '@/store/models/blocks/ResourceBlock'
import StatefulFormInputBlock from '@/store/models/blocks/StatefulFormInputBlock'
import VideoBlock from '@/store/models/blocks/VideoBlock'
import ExternalHookButtonBlockVue from '@/components/block/ExternalHookButtonBlock.vue'
import PageBlockVue from '@/components/block/PageBlockVue.vue'
// Define blockTypes as a plain object with properties for each type
export const blockTypes = {
  paragraph: {
    type: 'paragraph',
    component: ParagraphBlockVue,
    modelClass: ParagraphBlock,
    displayTitleString: i18n.t('blockTypes.paragraph.title'),
    displaySubtitleString: i18n.t('blockTypes.paragraph.subtitle'),
    icon: 'mdi-text-long',
  },
  header1: {
    type: 'header',
    component: HeaderBlockVue,
    modelClass: HeaderBlock,
    displayTitleString: i18n.t('blockTypes.header1.title'),
    displaySubtitleString: i18n.t('blockTypes.header1.subtitle'),
    icon: 'mdi-format-header-1',
    defaultProps: {
      level: HEADER_LEVELS[1].backendName
    },
    match: (blockData) => {
      return parseInt(blockData.header.level) === HEADER_LEVELS[1].backendName
    }
  },
  header2: {
    type: 'header',
    component: HeaderBlockVue,
    modelClass: HeaderBlock,
    displayTitleString: i18n.t('blockTypes.header2.title'),
    displaySubtitleString: i18n.t('blockTypes.header2.subtitle'),
    icon: 'mdi-format-header-2',
    defaultProps: {
      level: HEADER_LEVELS[2].backendName
    },
    match: (blockData) => {
      return parseInt(blockData.header.level) === HEADER_LEVELS[2].backendName
    }
  },
  header3: {
    type: 'header',
    component: HeaderBlockVue,
    modelClass: HeaderBlock,
    displayTitleString: i18n.t('blockTypes.header3.title'),
    displaySubtitleString: i18n.t('blockTypes.header3.subtitle'),
    icon: 'mdi-format-header-3',
    defaultProps: {
      level: HEADER_LEVELS[3].backendName
    },
    match: (blockData) => {
      return parseInt(blockData.header.level) === HEADER_LEVELS[3].backendName
    }
  },
  string: {
    type: 'string',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.text'),
    icon: 'mdi-alphabetical-variant',
  },
  grid: {
    type: 'grid',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.grid'),
    icon: 'mdi-grid-large',
  },
  dateandtime: {
    type: 'dateAndTimeInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.dateTime'),
    icon: 'mdi-calendar-clock',
  },
  dictionary: {
    type: 'dictionary',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.dictionary'),
    icon: 'mdi-book-open-page-variant',
  },
  attachmentcollection: {
    type: 'attachmentCollectionInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.attachmentCollection'),
    icon: 'mdi-paperclip',
  },
  uri: {
    type: 'uriInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.uri'),
    icon: 'mdi-web',
  },
  decimal: {
    type: 'decimalInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.decimal'),
    icon: 'mdi-decimal',
  },
  mimeentitycollection: {
    type: 'mimeentitycollection',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.mimeEntityCollection'),
    icon: 'mdi-email-multiple',
  },
  entity: {
    type: 'entityInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.entity'),
    icon: 'mdi-account-box',
  },
  integer: {
    type: 'integerInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.integer'),
    icon: 'mdi-numeric',
  },
  mimeentity: {
    type: 'mimeentity',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.mimeEntity'),
    icon: 'mdi-email',
  },
  enumcollection: {
    type: 'enumCollectionInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.enumCollection'),
    icon: 'mdi-format-list-bulleted',
  },
  user: {
    type: 'userInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.user'),
    icon: 'mdi-account',
  },
  location: {
    type: 'geoLocationInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.location'),
    icon: 'mdi-map-marker',
  },
  boolean: {
    type: 'booleanInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.boolean'),
    icon: 'mdi-toggle-switch',
  },
  currency: {
    type: 'currencyInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.currency'),
    icon: 'mdi-currency-usd',
  },
  date: {
    type: 'dateInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.date'),
    icon: 'mdi-calendar',
  },
  multitext: {
    type: 'multitext',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.multiText'),
    icon: 'mdi-text-box-multiple',
  },
  address: {
    type: 'addressInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.address'),
    icon: 'mdi-home-city',
  },
  enum: {
    type: 'enumInput',
    component: CellInputBlockVue,
    modelClass: StatefulFormInputBlock,
    displayTitleString: i18n.t('blockTypes.enum'),
    icon: 'mdi-format-list-bulleted-type',
  },
  text: {
    type: 'textInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.text'),
    icon: 'mdi-text',
  },
  entitycollection: {
    type: 'entitycollectionInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.entityCollection'),
    icon: 'mdi-account-multiple',
  },
  signature: {
    type: 'signatureInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.signature'),
    icon: 'mdi-pen',
  },
  email: {
    type: 'emailInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.email'),
    icon: 'mdi-email',
  },
  phonenumber: {
    type: 'phoneNumberInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.phoneNumber'),
    icon: 'mdi-phone',
  },
  attachment: {
    type: 'attachmentInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.attachment'),
    icon: 'mdi-attachment',
  },
  resourceInput: {
    type: 'resourceInput',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.resource'),
    icon: 'mdi-link-variant',
  },
  resourceBlock: {
    type: 'resourceBlock',
    component: ResourceBlockVue,
    modelClass: ResourceBlock,
    displayTitleString: i18n.t('blockTypes.resourceBlock.title'),
    displaySubtitleString: i18n.t('blockTypes.resourceBlock.subtitle'),
    icon: 'mdi-table',
    defaultProps: {
      type: {
        name: 'resource',
        validResourceTypes: ['view']
      }
    },
    match: (blockData) => {
      return blockData.resourceBlock.type.name === 'resource' && blockData.resourceBlock.type.validResourceTypes?.includes('view')
    }
  }, 
  externalHookButtonBlock: {
    type: 'resourceBlock',
    component: ExternalHookButtonBlockVue,
    modelClass: ResourceBlock,
    displayTitleString: i18n.t('blockTypes.externalHookButtonBlock.title'),
    displaySubtitleString: i18n.t('blockTypes.externalHookButtonBlock.subtitle'),
    icon: 'mdi-button-cursor',
    defaultProps: {
      type: {
        name: 'resource',
        validResourceTypes: ['externalHook']
      }
    },
    match: (blockData) => {
      return blockData.resourceBlock.type.name === 'resource' && blockData.resourceBlock.type.validResourceTypes?.includes('externalHook')
    }
  },
  block: {
    type: 'block',
    component: CellInputBlockVue,
    modelClass: FormInputBlock,
    displayTitleString: i18n.t('blockTypes.block'),
    icon: 'mdi-file-document-outline',
  },
  page: {
    type: 'page',
    component: PageBlockVue,
    modelClass: PageBlock,
    displayTitleString: i18n.t('blockTypes.page.title'),
    displaySubtitleString: i18n.t('blockTypes.page.subtitle'),
    icon: 'mdi-file-document-outline',
  },
  embed: {
    type: 'IFrame',
    component: EmbedBlockVue,
    modelClass: EmbedBlock,
    displayTitleString: i18n.t('blockTypes.embed.title'),
    displaySubtitleString: i18n.t('blockTypes.embed.subtitle'),
    icon: 'mdi-application-outline',
  },
  video: {
    type: 'videoInput',
    component: VideoBlockVue,
    modelClass: VideoBlock,
    displayTitleString: i18n.t('blockTypes.video.title'),
    displaySubtitleString: i18n.t('blockTypes.video.subtitle'),
    icon: 'mdi-play-box-outline',
  }
}
