<template>
  <div v-if="block" class="block-page-root" data-testid="blockPage">
    <LoadingOverlay :show="loading" />
    <v-img
      v-if="headerImageUrl"
      :src="headerImageUrl"
      class="headerImage"
    />
    <div :class="{ 'mobile-container': $vuetify.breakpoint.mobile && canUpdate }" class="mx-16 my-4">
      <AGDraggable
        :disabled="!canUpdate"
        v-model="block.children"
        @change="onDragChange"
        handle=".grabbable"
      >
        <!-- Grid system with a single row and centered content -->

        <v-row            
          v-for="(child, i) in block.children"
          :key="child.id"
          justify="center"
        >
        <v-col
        v-bind="columnSize(child)"
      >
            <component
              :ref="`block_${child.id}`"
              :is="component(child)"
              :blockUri="child.uri"
              :editorMode="canEdit"
              @updateBlock="() => updateBlock(child)"
              @delete="deleteChild(child)"
              @addBlockAfter="() => blockPickerIndex = i + 1"
              @addParagraphBlock="text => addParagraphBlock(text, i + 2, false)"
            />
            <!-- Conditionally display the block picker after the block if it's the chosen index -->
            <BlockPicker
              :autofocus="true"
              v-if="blockPickerIndex === i + 1"
              @blockSelected="(type, defaultProps) => addChild(type, i + 2, defaultProps)"
              @addParagraphBlock="text => addParagraphBlock(text, i + 2)"
            />
          </v-col>
      </v-row>
        </AGDraggable>
        <v-row            
            justify="center"
          >
        <v-col
          cols="12"
          sm="10"
          md="9"
          lg="7"
        >
          <BlockPicker
            v-if="canAddChild"
            data-testid="blockPicker"
            @blockSelected="(type, defaultProps) => addChild(type, block.childCount + 1, defaultProps)"
            @addParagraphBlock="text => addParagraphBlock(text, block.childCount + 1)"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BlockPicker from '@/components/block/editor/BlockPicker.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import CellInputBlock from '@/components/block/CellInputBlock.vue'
import HeaderBlock from '@/store/models/blocks/HeaderBlock'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import AGDraggable from '@/components/AGDraggable.vue'

export default {
  props: {
    blockUri: {
      type: String
    },
    editorMode: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      loading: false,
      blockPickerIndex: null
    }
  },
  computed: {
    headerImageUrl() {
      return this.block?.headerImageUrl
    },
    canAddChild() {
      return hasPermission(this.block, [ PERMISSIONS.addChild ]) && this.editorMode
    },
    canEdit() {
      return hasPermission(this.block, [ PERMISSIONS.patch ]) && this.editorMode
    },
    canUpdate() {
      return hasPermission(this.block, [ PERMISSIONS.update ]) && this.editorMode
    },
    title() {
      return this.block?.name || this.$t('blocks.emptyPageTitle')
    },
    block() {
      return this.$store.getters.blockWithUri(this.blockUri)
    },
  },
  watch: {
    blockUri: {
      immediate: true,
      async handler(newVal) {
        if (newVal == null) {
          return
        }
        this.loading = true
        try {
          this.loadBlock()
        } finally {
          this.loading = false
        }
      }
    },
    title: {
      immediate: true,
         handler(newVal) {
          document.title = newVal
        }
    }
  },
  provide() {
    return {
      canAddChild: () => this.canAddChild
    }
  },
  methods: {
    async loadBlock() {
      try {
        if(this.block) {
          await this.block.reload()
        }
        else {
          console.log('page loads block')
          await this.$store.dispatch('AGReadBlock', { blockUri: this.blockUri, customErrorHandling: [400]})
        }
        // New pages come with an empty header, focus it
        if (this.block.children.length === 1 && this.block.children[0] instanceof HeaderBlock && !this.block.children[0].text) {
          await this.$nextTick()
          this.focus(this.block.children[0])
        }
      } catch (error) { 
          if (error.response?.status === 400) {
            this.$emit('unaccessibleView')
          }
      }
    },
    columnSize(child) {
      const isFullWidth = child.width === 'fullwidth'
      return {
        cols: 12,
        sm: isFullWidth ? 12 : 10,
        md: isFullWidth ? 12 : 9,
        lg: isFullWidth ? 12 : 7
      }
    },
    async deleteChild(child) {
      if (!this.canEdit) {
        return
      }
      child.deleted = true
      await child.deleteBlockIn(this.block)
      this.loadBlock()
    },
    async addChild(type, position, defaultProps = {}) {
      if (!this.canEdit) {
        return
      }
      if (type == null) {
        this.blockPickerIndex = null
        return
      }
      const newBlock = await this.$store.dispatch('AGAddBlockChild', {
        block: this.block,
        type: type,
        position: position,
        blockProps: {
          [type]: {
            ...defaultProps
          }
        }
      })
      this.focus(newBlock)
    },
    async addParagraphBlock(text, position, focusPicker = true) {
      if (!this.canEdit) {
        return
      }
      const newParagraphBlock = await this.$store.dispatch('AGAddBlockChild', {
        block: this.block,
        type: 'paragraph',
        position: position
      })
      if (text) {
        newParagraphBlock.text = text
        await newParagraphBlock.updateValue()
      }

      await this.$nextTick()
      if (focusPicker) {
        this.blockPickerIndex = position
      } else {
        this.focus(newParagraphBlock)
      }
    },
    component(block) {
      return block.blockType.component
    },
    focus(block) {
      this.$refs[ `block_${block.id}` ][ 0 ].focus?.()
    },
    async updateBlock(block) {
      if (block.deleted || !this.canEdit) {
        return
      }
      block.updateValue()
      const firstHeader = this.block.children.find(child => child instanceof HeaderBlock)
      if (!this.block.name && firstHeader != null && firstHeader.uri === block.uri) {
        await this.block.patchName(block.text)
      }
    },
    onDragChange() {
      this.block.update()
    },
  },
  components: {
    CellInputBlock,
    BlockPicker,
    LoadingOverlay,
    AGDraggable
  }
}
</script>

<style scoped>
.block-page-root {
  width: 100%;
  background: white;
  position: inherit;
  overflow: auto;
}

.headerImage {
  object-fit: cover;
  max-height: 240px;
  min-height: 220px;
}

.mobile-container {
  padding-left: 48px;
}
</style>
